@if $password_strengthen {
  #signin {
    .sign-in-component {
      .password-field {
        overflow: visible;
        &__info {
          width: 23%;
          #{$rdirection}: 50%;
          &::before {
            #{$rdirection}: 100%;
            #{$ldirection}: auto;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
      }
    }
  }
  .profile-info {
    &__item {
      clear: both;
    }
  }
  .password-update-overlay {
    .profile-password-update {
      &__fieldset {
        .form-item input[type='password'] {
          width: 100%;
        }
      }
      &__rules {
        li {
          display: flex;
        }
      }
    }
  }
}

#colorbox {
  &.disconnect-overlay-launch {
    height: 200px !important;
    top: 1950px !important;
    @media #{$medium-up} {
      height: auto;
    }
    .disconnect-overlay {
      padding-top: 25px;
      font-size: 16px;
      @media #{$medium-up} {
        padding-top: 0;
      }
    }
    .disconnect-confirmation {
      cursor: pointer;
      padding: 10px;
      font-weight: bold;
      float: #{$ldirection};
      margin-top: 10px;
      background-color: $color-black;
      color: $color-white;
      &.disconnect-yes {
        margin-#{$ldirection}: 10px;
      }
    }
  }
}

#registration {
  .social-info {
    .social-disconnect {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
