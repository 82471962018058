//Colors

$textarea-label-color: #999;

.sign-in-component {
  .sign-in-component__form--registration {
    .sign-in-component__password-wrapper {
      .q_password[type='password'] {
        float: none;
      }
      .password_wrapper {
        float: left;
        width: 226px;
        .label {
          margin-bottom: 1px;
        }
      }
    }
    .mobile_part {
      select,
      .selectbox,
      .field[type='tel'] {
        width: 170px;
      }
    }
  }
  .form-item {
    margin-bottom: 5px;
    padding-top: 5px;
  }
}

a {
  &.selectBox,
  &.selectbox {
    line-height: 47px;
    height: 47px;
    vertical-align: top;
  }
}

.offer_code_form_container {
  p {
    margin: 10px 0;
  }
}

.adpl {
  input.error[type='text'],
  input.error[type='tel'],
  input.error[type='email'],
  input.error[type='password'],
  textarea.error,
  select.error {
    border-color: $color-red;
  }
  textarea {
    height: 9.5em;
    height: calc(9.5em - -2px);
    font-size: 1em;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-box-shadow: none;
    box-shadow: none;
    resize: none;
    outline: 0;
    border-color: $color-light-gray;
    width: 100%;
    & + label {
      display: block;
      font-size: 1em;
      pointer-events: none;
      height: 9.5em;
      height: calc(9.5em - -2px);
      line-height: 1;
      padding-top: 1em;
      padding-top: calc(1em - -1px);
      margin-top: 9.5em;
      margin-top: calc(-9.5em - 2px);
      cursor: text;
      &:before {
        content: attr(data-required) attr(placeholder);
        display: inline-block;
        color: $textarea-label-color;
        margin: 1em;
        margin: 0 calc(1em - -2px);
        white-space: nowrap;
        -webkit-transition-property: -webkit-transform, color;
        transition-property: transform, color;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-delay: 0;
        transition-delay: 0;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        -webkit-transform-origin: left center;
        -ms-transform-origin: left center;
        transform-origin: left center;
        text-transform: uppercase;
      }
      span.label-content {
        display: none;
      }
    }
    &.active + label:before,
    &.js-label-mode + label:before,
    &:focus + label:before {
      background: $color-white;
      line-height: 1;
      padding: 0 1.5px;
      -webkit-transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
      transform: translateY(-1.5em) translateY(-0.5px) scale(0.8, 0.8);
    }
    &.js-label-mode + label:before {
      content: attr(data-required) attr(alt);
    }
    &.filled-in {
      @include box-shadow(none);
      border-color: $color-black;
      color: $color-black;
      &.error {
        border-color: $color-red;
      }
    }
  }
}

.visible-pass-wrapper {
  clear: both;
  float: left;
  .label {
    float: left;
  }
}

.account-page {
  .registration-page {
    &__password {
      &.mobile-number {
        select,
        .selectbox {
          width: 170px;
        }
      }
    }
  }
}

#shipping_billing {
  .address-form {
    .form-item {
      margin-bottom: 12px;
      &.address-form {
        &__phone-1-container,
        &__phone-2-container {
          .selectBox {
            width: 30%;
          }
          input.field {
            padding-top: 0;
          }
        }
      }
    }
  }
}
