.section-store-locator {
  .store-locator__nav {
    .store-locator__nav-item.last {
      display: none !important;
    }
  }
}

.lpPoweredBy,
.lpEmtStarRating {
  display: none;
}

.page-wrapper {
  .customer-service {
    .cs-quick-info {
      .cs-quick-info__block {
        table.lpStaticButton {
          margin-bottom: 0px;
        }
      }
    }
    .contact-us-page {
      .contact-form {
        .address-form__phone-1-container {
          input.field {
            width: 100%;
          }
        }
        .address-form__accept_privacy-container {
          padding: 0;
        }
        .address-form__order-number-container,
        .address-form__title_name-container {
          width: 569px;
          input.field {
            width: 50%;
          }
          .label {
            width: 100%;
          }
        }
        .address-form__registered-container {
          label.text--checkbox-label {
            margin-right: 20px;
          }
        }
      }
      .form-item {
        .label {
          display: inline-block;
        }
      }
    }
  }
  .mpp__product-sort-container {
    .mpp__product-sort.selectBox-menuShowing {
      border-color: #040a2b !important;
    }
  }
  .spp {
    .spp-product__anchor {
      width: 197px;
      .spp-product__anchor-nav {
        padding-top: 16px;
        li.spp-product__anchor-help {
          display: none;
        }
      }
      .spp-product__anchor-tab {
        background-position: 0 -8275px;
        right: 0;
        width: 47px;
        height: 47px;
        top: 0%;
      }
      &.is_closed {
        left: -159px;
      }
    }
  }
  .page-footer {
    .beauty-chat-tray {
      .lpStaticButton {
        margin: 1em auto;
      }
    }
    .drawer-formatter__content {
      .special-offers-tray {
        .offers-email-signup-form {
          display: none;
        }
      }
    }
    .page-sticky-footer {
      &__left {
        .node-elc-nodeblock {
          &.special-offers-elc-nodeblock {
            border-left: none;
          }
        }
      }
    }
  }
}

.page-products-16218 {
  .product_brief__sub-panel-buttons-container {
    .product_brief__quantity-container {
      .product_brief__quantity {
        display: none !important;
      }
    }
    a.product_brief__button--add-to-bag {
      margin-left: 34px;
    }
  }
}

.page-product-16218 {
  .product-full__button-container {
    .product-full__quantity {
      display: none !important;
    }
    .product-full__add-button {
      margin-left: 0px;
    }
  }
  .spp-product__mini-bag-button-container {
    .spp-product__mini-bag-quantity {
      display: none !important;
    }
    .spp-product__mini-bag-add-button {
      margin-left: 0px;
    }
  }
}

.ff-quiz__inner {
  .ff-quiz {
    &__coverage-image {
      font-size: 130px;
      letter-spacing: -5px;
      &--sheer,
      &--medium,
      &--full {
        font-size: 75px;
        letter-spacing: -0.05em;
      }
    }
    &__headline--secondary {
      font-size: 30px;
    }
  }
  .slick-list {
    .ff-quiz__slide {
      .ff-quiz__headline {
        font-size: 60px;
      }
    }
  }
}

.eyeshadow-elc-mpp {
  .product-hero-tout {
    .tout__product-copy {
      top: 70%;
    }
  }
  .tout-headline {
    .header__headline {
      span {
        line-height: 125px;
      }
    }
  }
}

.cleansers-toners-elc-mpp {
  .hero-block__headline {
    width: 50%;
    top: 15% !important;
    .header__headline {
      span {
        line-height: 125px;
      }
    }
  }
  .hero-block__promo {
    top: 65% !important;
  }
}

.foundation-custom-mpp-page {
  .hero-block__promo {
    top: 55% !important;
  }
}

.contouring-page {
  .hero-block__promo {
    top: 58% !important;
  }
}

.landing-best-sellers-page {
  .hero-block__headline {
    width: 50%;
    top: 10% !important;
    .header__headline {
      span {
        line-height: 135px;
      }
    }
  }
  .hero-block__promo {
    top: 70% !important;
  }
}

.new-dimension-page {
  .hero-block__headline {
    .header__headline {
      line-height: 60px;
    }
  }
  .hero-block__promo {
    top: 55% !important;
  }
}

.lip-gloss-elc-mpp {
  .hero-block__headline {
    top: 12% !important;
  }
}

.mpp-compare {
  .mpp-compare__cart {
    .mpp-compare__bag-container {
      min-height: 40px;
    }
    .compare_add_to_bag {
      .button--secondary {
        border: 1px solid #fff;
      }
    }
  }
  td.mpp-compare__bag {
    padding-bottom: 20px;
  }
}

.spp-product__details {
  .spp-product__details-description {
    line-height: 25px;
  }
}
/* temp update untill we make this a global svg image */

.spp-product__details-ingredients {
  &-more {
    background-image: url('/media/export/cms_2.0/icons/product_details_open.png');
  }
  &-less {
    background-image: url('/media/export/cms_2.0/icons/product_details_close.png');
  }
}

.hero-block__boutique,
.hero-block__cta {
  margin-top: 25px !important;
}

.mpp__product {
  .product_brief__headers {
    h3.product_brief__header.product_brief__sub-line {
      margin: 10px 0 0 0;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.mpp__header {
  font-weight: normal;
  padding-bottom: 15px;
}

.spp-product__details-container {
  .spp-product__details {
    .spp-product__details-header {
      margin-bottom: 40px;
    }
    .spp-product__details-column {
      .spp-product__details-description {
        margin-bottom: 35px;
        line-height: 24px;
        font-size: 15px;
      }
      .spp-product__details-attribute {
        .spp-product__details-attribute__label {
          font-size: 12px;
        }
        p {
          margin-top: 20px;
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
    .spp-product__details-column02 {
      .spp-product__details-attribute {
        p {
          margin-top: 15px;
          font-size: 12px;
          line-height: 23px;
        }
      }
    }
  }
}

.mpp__product {
  .product_brief__buttons-container {
    .product_brief__buttons--non-shaded,
    .product_brief__buttons--shaded {
      left: 65px;
    }
  }
}

.brand-renutriv {
  .page-wrapper {
    .spp-product__anchor-nav {
      padding-top: 21px;
      li.spp-product__anchor-help {
        padding: 0px;
      }
    }
    .spp-product__anchor-tab {
      top: 0%;
    }
  }
}

.elc-user-state-logged-in #colorbox #cboxClose,
.elc-user-state-anonymous #colorbox #cboxClose {
  right: 25px;
  top: 25px;
}

#cboxLoadedContent {
  margin: 35px 0 15px 0;
}

.brand-aerin {
  .product_brief__button-panel {
    border: 1px solid #cccccc;
    background: #8f92a4 url('/media/export/cms/aerin/mpps/aerin_quickshop.png') no-repeat center;
  }
}

.section-esearch {
  .search-page {
    .search-page__filters {
      visibility: hidden;
    }
  }
}

#shipping_billing {
  .address-form {
    .form-item {
      padding-left: 0px;
      margin-bottom: 5px;
    }
    .address_lookup_submit {
      line-height: 35px;
      height: 35px;
      width: 50%;
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP_Korean.jpg');
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .confirm-container {
        max-width: 598px;
        .total-time {
          padding: 18px 20px;
        }
      }
    }
  }
}

.product-vto__youcam-module-container {
  @media #{$medium-up} {
    z-index: 99;
  }
}

.product-vto {
  .cta-vto {
    z-index: 98;
  }
}

@media #{$medium-up} {
  .page-footer {
    .footer-legal {
      .menu {
        display: block;
        li {
          float: left;
          padding-right: 50px;
          &.last {
            float: right;
            padding: 0;
          }
        }
      }
    }
  }
}
