.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -22px;
      background-image: url('/media/images/global/lo3.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-branding__logo {
  float: right;
}

.trust_mark_footer {
  margin-left: 0px;
  margin-right: 0px;
  border-top: 1px solid #a4a4ab;
  padding-top: 10px;
  padding-bottom: 10px;
  .trust_mark_footer__link {
    width: 128px;
    height: 145px;
    background-image: url('/media/images/global/lo2-white.png');
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
    margin: auto;
  }
}

#address-lookup-container {
  .address-search {
    padding-top: 20px;
  }
}

.email_wish_popover {
  .fieldset {
    .headline--section {
      font-size: 30px;
    }
  }
}

#shipping_billing {
  .address-form {
    .main-button-wrapper {
      margin-left: 20px;
      clear: both;
    }
    .submit_btn {
      margin-left: 20px;
      clear: both;
    }
    .address-lookup {
      width: 100%;
    }
    .address1_container {
      clear: both;
      width: 90%;
    }
    .address2_container {
      clear: both;
      width: 90%;
    }
    .address3_container {
      clear: both;
      width: 90%;
    }
    .address-form__address1-container {
      clear: both;
      width: 90%;
    }
    .address-form__address2-container {
      clear: both;
      width: 90%;
    }
    .address-form__address3-container {
      clear: both;
      width: 90%;
    }
  }
  #address-lookup-container {
    .address-search {
      padding-top: 0px;
      margin-top: 0px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 24px;
      h4 {
        font-size: 36px;
        font-weight: bold;
        padding-bottom: 10px;
      }
      .address-info {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 11px;
      }
      #rna_keyword {
        width: 67%;
        height: 3.3em;
      }
      a.town-submit-btn {
        margin-left: 7px;
        width: 30%;
      }
      .keyword-hint {
        font-size: 13px;
        padding-top: 11px;
      }
    }
    .address-hint-container {
      h4 {
        font-size: 14px;
        padding-bottom: 10px;
        font-weight: bold;
      }
      .hint-table {
        margin-top: 5px;
        width: 100%;
        th {
          padding: 10px 0 10px 0;
          background: #f2f3f4;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
        }
        td {
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #000;
        }
      }
      .building-name-registered {
        padding-top: 11px;
        font-weight: normal;
      }
    }
    .address-results-container {
      padding-top: 11px;
      .search-results-header {
        font-size: 14px;
        font-weight: bold;
      }
      .address1 {
        display: block;
        padding: 5px 0 5px 0;
        cursor: pointer;
      }
      .address3 {
        cursor: pointer;
      }
      .rna-list {
        margin-top: 5px;
        width: 100%;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        th.sno,
        th.full-address {
          background: #f2f3f4;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        th.postal {
          background: #f2f3f4;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000;
          padding-bottom: 10px;
          padding-top: 10px;
        }
        td.sno,
        td.postal {
          width: 10%;
          text-align: center;
          padding-bottom: 15px;
          padding-top: 10px;
          border-bottom: 1px solid #ccc;
          font-size: 12px;
        }
        td.full-address {
          width: 60%;
          text-align: center;
          padding-bottom: 15px;
          padding-top: 10px;
          border-bottom: 1px solid #ccc;
          font-size: 13px;
          cursor: pointer;
        }
        .roadAddrPart1 {
          display: none;
        }
        .roadAddrPart2 {
          display: none;
        }
      }
      #rna-page-list {
        margin-top: 10px;
        text-align: center;
      }
    }
    .error-display {
      border-bottom: 1px solid #ccc;
      padding-bottom: 76px;
    }
    .error-msg,
    .error_messages {
      color: #000;
      text-align: center;
      font-weight: bold;
      padding-top: 47px;
    }
    .address-input-container {
      .address-input-submit {
        a.input-submit-btn {
          font-size: 12px;
          font-family: 'AkzidenzGrotesk W1G';
          letter-spacing: 0.15em;
          text-transform: uppercase;
          font-weight: bold;
          vertical-align: middle;
          background: #040a2b;
          color: white;
          text-align: center;
          -webkit-appearance: none;
          -webkit-font-smoothing: antialiased;
          text-decoration: none;
          display: inline-block;
          vertical-align: middle;
          margin-left: 34%;
          margin-top: 4%;
          width: 40%;
          cursor: pointer;
        }
      }
      .zip {
        display: none;
      }
    }
    .address-details {
      .detail-address {
        label {
          font-weight: bold;
        }
        .input_address_2 {
          width: 80%;
          margin-left: 18px;
        }
      }
      .building {
        margin-left: 65px;
        margin-top: 10px;
      }
      .address-input-submit {
        width: 55%;
        margin: 0 auto;
      }
      .street {
        margin: 10px 0;
        label {
          font-weight: bold;
        }
        .ra1 {
          margin-left: 15px;
        }
      }
      .input-address2 {
        display: none;
      }
    }
    #rna-loading {
      display: none;
      margin: 10px auto;
      width: 60px;
    }
  }
}

#order_return {
  #return-address {
    #address_form_container {
      label {
        width: 98%;
        display: block;
      }
      input {
        width: 46% !important;
        clear: both;
        float: left;
      }
      span.required_mark {
        float: left;
      }
      .address_lookup_submit {
        height: 34px;
        line-height: 34px;
        margin-left: 10px;
      }
      .phone {
        fieldset {
          clear: both;
          input {
            width: 15% !important;
            float: left;
            margin-right: 5px;
            clear: none;
          }
          select {
            width: 15% !important;
            float: left;
            margin-right: 5px;
          }
        }
      }
      #form--address--field--POSTAL_CODE {
        width: 32% !important;
      }
      .example-zipcode {
        clear: both;
      }
    }
    .edit-address {
      text-decoration: underline;
      margin-left: 10px;
    }
  }
}

#address-confirmation {
  padding: 10px;
  width: 100%;
  text-align: center;
  h2 {
    font-size: 16px;
    color: #000;
    margin: 0 0 10px 0;
    font-weight: bold;
  }
  .address-confirm-buttons {
    margin: 20px 0 0 55px;
    .address-cancel {
      float: left;
      width: 35%;
      margin-left: 5px;
      .grey-button {
        font-family: 'HelveticaNeueLTStd65Medium', Verdana, Arial, Sans-Serif;
        background-color: #999;
        text-transform: uppercase;
        color: #ccc;
        line-height: 1.5;
        font-size: 0.9em;
        text-align: center;
        letter-spacing: 0;
        border: 0;
        padding: 4px 10px 3px 10px;
        display: inline-block;
        zoom: 1;
        font-weight: bold;
      }
      .update-cancel {
        height: 40px;
        line-height: 31px;
        color: #fff;
        margin-left: 10px;
        width: 100%;
        cursor: pointer;
      }
    }
    .address-confirm {
      width: 35%;
      float: left;
      .update-confirm {
        width: 100%;
      }
    }
  }
  .change-address-content {
    width: 100%;
    padding: 10px 0 10px 0;
    line-height: 18px;
    margin: 0 auto 0 auto;
  }
}

.contactus_overlay {
  #cboxLoadedContent {
    margin: 0;
  }
  #contact-overlay {
    margin-top: 50px;
    h2 {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }
    div.pop-search2 {
      text-align: center;
      strong {
        font-family: 'OptimaDisplayLight', 'NanumBarun Gothic Ultralight', 'Dotum', 'Golum';
      }
    }
  }
}

.loyalty_popover__content {
  overflow-y: hidden;
}

.hide_qty_dropdown {
  display: none !important;
}

.brand-aerin {
  .product_brief__header {
    padding-top: 0;
    margin-bottom: 0;
  }
}

.product_brief__headers {
  min-height: 115px;
}

.product_brief__buttons-container {
  .product_brief__desc1-reviews {
    min-height: 120px;
    margin-top: 10px;
  }
}

.product_brief__misc-flag-spacer {
  display: block !important;
}

.product_brief__header ~ .product_brief__misc-flag-spacer {
  display: none !important;
}

// 2019 Creative refresh
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.utility-nav__account {
  @media #{$medium-up} {
    left: -20px;
  }
  @media #{$xlarge-up} {
    left: -10px;
  }
  &.utility-nav__loyalty {
    .user-loyalty-state {
      display: none;
    }
  }
}

.utility-nav__cart {
  @media #{$medium-up} {
    left: 26px;
  }
  @media #{$xlarge-up} {
    left: 50px;
  }
}

.page-header.alt-color-white {
  .header-items {
    @media #{$xlarge-up} {
      padding: 17px 0;
      min-height: 65px;
    }
  }
  .page-navigation__menu {
    padding-top: 10px;
    .menu-ref {
      .menu--column-width__20 > li.menu__item.menu-item-image {
        @media #{$large-up} {
          position: relative;
          float: #{$rdirection};
          width: 100%;
          max-width: 20%;
        }
      }
      .menu__item--category {
        @media #{$large-up} {
          font-size: 17px;
          letter-spacing: 0.75px;
          font-weight: 700;
        }
        &-expandable:not(:first-child) {
          @media #{$xlarge-up} {
            padding-top: 11px;
          }
        }
      }
    }
  }
  .page-navigation {
    .menu-ref__title {
      span {
        font-family: $akzidenz;
      }
      a {
        font-family: $akzidenz;
      }
    }
    .menu-ref__content {
      .site-header-menu-formatter-v1 {
        span {
          font-family: $akzidenz;
        }
        a {
          font-family: $akzidenz;
        }
      }
    }
  }
}

.section-aerin,
.brand-aerin,
.section-re-nutriv,
.brand-renutriv {
  .page-wrapper {
    .page-navigation__menu {
      a {
        color: $color-dark-navy !important;
      }
    }
  }
}

.section-re-nutriv,
.brand-renutriv,
#beauty_feed {
  .page-header {
    .trust_mark_header__link {
      background-image: url('/media/images/global/lo3-white.png');
    }
    .page-navigation__menu {
      a {
        @media #{$xlarge-up} {
          color: $color-white !important;
        }
      }
    }
    &.is-alternate {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3.png');
      }
      .page-navigation__menu {
        a {
          @media #{$xlarge-up} {
            color: $color-dark-navy !important;
          }
        }
      }
    }
  }
}

.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-full__add-button {
        font-size: 10px;
        letter-spacing: 1px;
        padding: 0 10px;
      }
    }
  }
}

#zero_dollar_overlay {
  h3,
  .popup_contents {
    text-align: center;
  }
  .checkout-buttons,
  .shopping-buttons {
    display: inline-block;
    a {
      background-color: $color-navy;
      color: $color-white;
    }
  }
}
