/* Fonts - PC */
@charset "UTF-8";

@font-face {
  font-family: 'NanumBarun Gothic Ultralight';
  font-style: normal;
  font-weight: 400;
  src: url('#{$netstorage-font-path}Nanum/NanumBarun-Gothic-Ultralight.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Nanum Gothic Regular';
  font-style: normal;
  font-weight: 400;
  src: url('#{$netstorage-font-path}Nanum/NanumGothic-Regular.woff2') format('woff2');
  font-display: swap;
}

$font_1: 'OptimaDisplayLight', 'NanumBarun Gothic Ultralight', 'Dotum', 'Golum';
$font_2: 'OptimaDisplayLight', 'NanumBarun Gothic Ultralight', 'Dotum', 'Golum', 'sans-serif', 'arial';
$font_3: $bb-roman, 'sans-serif', 'Tahoma', 'arial';
$font_4: 'OptimaDisplayLight', 'Nanum Gothic Regular', 'Dotum', 'Golum';
$font_5: 'Optima Display Roman', 'NanumBarun Gothic Ultralight', 'Dotum', 'Golum', 'sans-serif', 'arial';
$font_6: 'AkzidenzGrotesk W1G', 'NanumBarun Gothic Ultralight';

body,
code,
kbd,
pre,
samp,
div,
ul,
li,
a,
p,
span,
table,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
input,
form,
dl,
dt,
dd,
label,
small,
select,
article,
strong {
  font-family: $font_1;
}

body {
  .page-main {
    font-family: $font_1;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: $font_1;
  }
  h5,
  h6 {
    font-family: $font_1;
  }
  .headline--primary,
  .headline--large,
  .headline--secondary,
  .headline--medium,
  .headline--tertiary,
  .headline--quaternary {
    font-family: $font_1;
  }
  .headline--quinary,
  .headline--senary {
    font-family: $font_1;
  }
  .headline--page,
  .headline--products {
    font-family: $font_1;
  }
  .headline--section,
  .profile-info__header {
    font-family: $font_1;
  }
  .headline--subsection {
    font-family: $font_1;
  }
  .pull-quote,
  blockquote {
    font-family: $font_1;
  }
  .text--bold,
  .text-small-header,
  .text--short,
  .text--short-copy,
  .text-short-body-copy,
  .text--tiny,
  .text--tiny-copy,
  .text--long-copy,
  .text-long-body-copy,
  .text--form-help,
  .text-form-submit {
    font-family: $font_1;
  }
  .text-primary-headlines,
  .text-medium-headlines,
  .text-product-names-main,
  .text-product-names-sub {
    font-family: $font_1;
  }
  label {
    font-family: $font_1;
  }
  p {
    font-family: $font_1;
  }
  .text--form-help p {
    font-family: $font_1;
  }
  .link,
  .link--bold,
  .section-head__link {
    font-family: $font_1;
  }
  .tabs.primary a,
  .tabs-component a {
    font-family: $font_1;
  }
  /* Page Navigation */
  .index-navigation,
  .page-navigation .depth-1 h3 {
    font-family: $font_1;
  }
  .page-utilities__help-text,
  .page-utilities__account-text,
  .page-utilities__signin-text,
  .page-utilities__cart-text,
  .utility-item-text,
  .page-navigation .level-1,
  .page-navigation .level-2,
  .page-navigation .level-3,
  .page-navigation__menu_text {
    font-family: $font_1;
  }
  .back-to-top {
    font-family: $font_1;
  }
  /* Promotions */
  .promo-unit__headline {
    font-family: $font_1;
  }
  .promo-unit__link {
    font-family: $font_1;
  }
  .promotions .nodeblock-signup-form ul {
    font-family: $font_1;
  }
  .email-and-sms-promotions {
    .overlay-addclass-privacy-policy-overlay {
      font-family: $font_1;
    }
  }
  /* Discover */
  .discover-more {
    h3.discover-more__header {
      font-family: $font_1;
      font-size: 50px;
    }
    h4.discover_more__region_header {
      font-family: $font_1;
      font-size: 16px;
    }
  }
  .discover_more__product-header,
  .discover_more__product-sub-header {
    font-family: $font_1;
  }
  /* Buttons */
  .button--dark,
  .button--light,
  .button--light-border,
  .btn,
  .cta,
  .continue-button-wrapper input[type='submit'],
  .checkout .submit input[type='submit'],
  .sample-select-button.selected,
  .viewcart-buttons .continue-checkout,
  .viewcart-buttons .go-shopping {
    font-family: $font_1;
  }
  /* Input Box */
  .text-form-text,
  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='password'],
  textarea,
  .form-text,
  select,
  a.selectBox,
  a.selectbox {
    font-family: $font_1;
  }
  .selectBox-options li a {
    font-family: $font_1;
  }
  .form-submit,
  .form-submit--light,
  .form-submit--selected,
  .form-submit--unselected,
  .form-submit--active,
  .form-submit--inactive {
    font-family: $font_1;
  }
  /* Checkout */
  .cart-item__product-name,
  .cart-item__product-subname,
  .cart-item__size {
    font-family: $font_1;
  }
  .cart-item__qty,
  .cart-header-text,
  .cart-header,
  .cart-item__color,
  .cart-item__remove-form,
  .cart-item__price,
  .cart-item__total {
    font-family: $font_1;
  }
  .checkout__panel-title {
    font-family: $font_1;
  }
  .checkout label {
    font-family: $font_1;
  }
  .checkout {
    .viewcart-header {
      .messages {
        font-family: $font_1;
      }
    }
    .sms-terms-and-conditions {
      a {
        font-family: $font_1;
      }
    }
  }
  .checkout-page-title {
    font-family: $font_1;
  }
  .checkout__subtitle {
    font-family: $font_1;
  }
  .checkout__header-item-count {
    font-family: $font_1;
  }
  .pc-place-order {
    font-family: $font_1;
  }
  .order-summary__subtotal-label,
  .order-summary__subtotal-value,
  .order-summary__shipping form,
  .order-summary__shipping-method,
  .order-summary__shipping-value,
  .order-summary__total-label,
  .order-summary__total-value {
    font-family: $font_1;
  }
  .discount,
  .subtotal,
  .tax,
  .continue-shopping {
    font-family: $font_1;
  }
  .recommended-products__list > li {
    .product_name {
      font-family: $font_1;
    }
    .produce_subname {
      font-family: $font_1;
    }
  }
  .payment-book__item,
  .address-book__item,
  .payment-book__controls-link {
    font-family: $font_1;
  }
  .payment-info-small {
    .payment-info-small__card-info-label {
      font-family: $font_1;
    }
    .payment-info-small__address-header {
      font-family: $font_1;
    }
  }
  .payment-form .label,
  .payment-form__address-fieldset .choose-address label {
    font-family: $font_1;
  }
  .payment-display {
    h3 {
      font-family: $font_1;
    }
  }
  .giftwrap.label,
  .giftwrap.value,
  .gift-card__link {
    font-family: $font_1;
  }
  .giftcard-page {
    .giftcard-label {
      font-family: $font_1;
    }
    .giftcard-copy {
      font-family: $font_1;
      p {
        font-family: $font_1;
      }
    }
  }
  .gift-card-display {
    font-family: $font_1;
  }
  .review-submit__header {
    font-family: $font_1;
  }
  .review-submit__product-head {
    font-family: $font_1;
  }
  .guarantee-panel__title {
    font-family: $font_1;
  }
  .checkout__sidebar {
    h2 {
      font-family: $font_1;
    }
    .guarantee-panel__title,
    .links-panel__title {
      font-family: $font_1;
    }
  }
  .sidebar-menu {
    .sidebar-menu__link {
      font-family: $font_1;
    }
  }
  .sidebar-chat {
    .sidebar-chat__header {
      font-family: $font_1;
    }
    .sidebar-chat__link {
      font-family: $font_1;
    }
  }
  .confirmation-panel h3 {
    font-family: $font_1;
  }
  /* SPP , MPP */
  .spp-product__details .spp-product__details-header {
    font-family: $font_1;
  }
  .mpp__header {
    font-family: $font_1;
  }
  .mpp-compare__title,
  .mpp-compare__label,
  .mpp-compare__header,
  .mpp-compare__sub-header {
    font-family: $font_1;
  }
  .mpp-product-compare-nav {
    li.mpp-compare-button {
      a {
        font-family: $font_1;
      }
      .compare-counter-wrapper .compare-counter {
        font-family: $font_1;
      }
    }
  }
  .mpp-product-compare-tooltip {
    .compare-info {
      font-family: $font_1;
    }
    .mpp-compare-now-button {
      font-family: $font_1;
    }
  }
  .spp-product__details .spp-product__details-attribute__label,
  .product_brief__price,
  .product_brief__label2 {
    font-family: $font_1;
  }
  .text-product-anchor,
  .spp-product__anchor,
  .spp-product,
  .product-full {
    font-family: $font_1;
  }
  .text-product-listing-names-main,
  .text-product-listing-names-sub {
    font-family: $font_1;
  }
  .product_brief__header,
  .product_brief__sub-header {
    font-family: $font_1;
  }
  .text-index-navigation,
  .product-thumb .product-thumb__title {
    font-family: $font_1;
  }
  .text-benefits-line-main,
  .text-benefits-line-sub {
    font-family: $font_1;
  }
  .product-thumb .product-thumb__price {
    font-family: $font_1;
  }
  .spp-product__attribute h5,
  .product-full__attribute h5 {
    font-family: $font_1;
  }
  .spp-product__review,
  .spp-product__attribute p,
  .product-full__review,
  .product-full__attribute p {
    font-family: $font_1;
  }
  .spp-product__mini-bag-section {
    font-family: $font_1;
  }
  .spp-product__mini-bag-header {
    font-family: $font_1;
  }
  .spp-product__mini-bag-sub-header {
    font-family: $font_1;
  }
  .spp-product__mini-bag-price-size {
    font-family: $font_1;
  }
  .text-prices,
  .text-size,
  .text-promo,
  .text-links {
    font-family: $font_1;
  }
  .wishlist-confirm__button-close,
  .spp-product__detail-link,
  .spp-product__share,
  .spp-product__wishlist,
  .product-full__detail-link,
  .product-full__share,
  .product-full__wishlist {
    font-family: $font_1;
  }
  .text-batch-2-primary-headline,
  .text-batch-2-medium-headline {
    font-family: $font_1;
  }
  .text-batch-2-links {
    font-family: $font_1;
  }
  .product-hero-tout .tout__product-copy .cta-button-wrapper .button--plus-sign {
    font-family: $font_1;
  }
  .mpp__category-links--content {
    font-family: $font_1;
  }
  .hero-block__boutique-link--label {
    font-family: $font_1;
  }
  .product-full__misc-flag,
  .product_brief__misc-flag {
    font-family: $font_1;
  }
  .ee-how-to__step__copy a {
    font-family: $font_1;
  }
  .ee--subhead,
  .ee-landing__filters li,
  .ee-landing__filters__side__wrapper {
    font-family: $font_1;
  }
  .pp__headline--sub-section,
  .pp__headline--sub-section-sub {
    font-family: $font_1;
  }
  .free_standard_shipping_returns {
    font-family: $font_1;
  }
  .terms-heading {
    font-family: $font_1;
  }
  #colorbox.colorbox__quickshop {
    .quickshop {
      font-family: $font_1;
      h3.quickshop__header {
        font-family: 'OptimaDisplayLight';
      }
      h4.quickshop__sub-header {
        font-size: 30px;
      }
    }
    .quickshop__view-full {
      font-family: $font_1;
    }
    .quickshop__sub-header {
      font-family: $font_2;
    }
    .quickshop__attribute {
      h5 {
        font-family: $font_1;
      }
      p {
        font-family: $font_1;
      }
    }
    .quickshop__add-button {
      font-family: $font_1;
    }
    .quickshop__detail-link {
      font-family: $font_1;
    }
    .quickshop__share {
      font-family: $font_1;
    }
    .quickshop__wishlist {
      font-family: $font_1;
    }
    .quickshop__tabs-control .quickshop__tab-control {
      font-family: $font_1;
    }
  }
  /* Search */
  .search-product__header,
  .search-product__sub-header {
    font-family: $font_1;
  }
  .el-search-block {
    input[type='text'].form-text {
      font-family: $font_1;
    }
    .el-search-block__links h5 {
      font-family: $font_1;
    }
    .el-search-block__links a {
      font-family: $font_1;
    }
  }
  .search-page {
    input[type='text'].search-term {
      font-family: $font_1;
    }
    .search-page__headline {
      font-family: $font_1;
    }
  }
  .search-product__misc-flag {
    font-family: $font_1;
  }
  .typeahead-wrapper {
    .product-result__info {
      font-family: $font_1;
    }
    .product-result__name {
      font-family: $font_1;
    }
    .product-result__sub-name {
      font-family: $font_1;
    }
  }
  .typeahead-summary p,
  .typeahead-see-results a {
    font-family: $font_1;
  }
  /* Account */
  #forgot_password,
  .password-sent-page .password-sent-page__content .password-sent-page__email-header {
    font-family: $font_1;
  }
  .sign-in-component {
    .sign-in-component__header {
      font-family: $font_1;
    }
    .sign-in-component__label,
    .sign-in-component__birthday-program label,
    .sign-in-component__sms-signup label {
      font-family: $font_1;
    }
    .sign-in-component__form--registration .sign-in-component__terms,
    .sign-in-component__form--registration .sign-in-component__terms p {
      font-family: $font_1;
    }
  }
  .account-data__controls-link,
  .delete-data-confirm__controls-link,
  .address-book__controls-link {
    font-family: $font_1;
  }
  .account-data-form__label {
    font-family: $font_1;
  }
  .last-purchased__header {
    font-family: $font_1;
  }
  .account-utilities {
    .account-utilities__header {
      font-family: $font_1;
    }
    .account-utilities__link {
      font-family: $font_1;
    }
    .account-utilities__link_livechat {
      font-family: $font_1;
    }
    .account-utilities__account-nav {
      .account-utilities__link.active {
        font-family: $font_1;
      }
    }
  }
  .registration-page {
    .label {
      font-family: $font_1;
      p {
        font-family: $font_1;
      }
    }
    .sms-promotions__copy {
      font-family: $font_1;
      p {
        font-family: $font_1;
      }
    }
  }
  .delete-address-confirm {
    .delete-address-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-payment-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-address-confirm__controls-link {
      font-family: $font_1;
    }
    .delete-payment-confirm__controls-link {
      font-family: $font_1;
    }
  }
  .delete-payment-confirm {
    .delete-address-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-payment-confirm__payment-info-header {
      font-family: $font_1;
    }
    .delete-address-confirm__controls-link {
      font-family: $font_1;
    }
    .delete-payment-confirm__controls-link {
      font-family: $font_1;
    }
  }
  .address-form .address-form__form-label {
    font-family: $font_1;
  }
  #address_form_container .ship_type_container label {
    font-family: $font_1;
  }
  #photo-upload-query div {
    font-family: $font_1;
  }
  /* Samples */
  .choose-samples-and,
  .remove-sample {
    font-family: $font_1;
  }
  .samples-page {
    .samples-panel__title {
      font-family: $font_1;
    }
    .available {
      font-family: $font_1;
    }
  }
  .samples .product-name {
    font-family: $font_1;
  }
  .samples-panel {
    .product-subhead {
      font-family: $font_1;
    }
    .product-size {
      font-family: $font_1;
    }
    .skus {
      label {
        font-family: $font_1;
      }
    }
  }
  .add-samples-message {
    font-family: $font_1;
  }
  /* Customer Service */
  .customer-service .sidebar-page__content {
    ul {
      font: 15px/24px $font_1;
    }
    ol {
      font: 15px/24px $font_1;
    }
    p {
      font-family: $font_1;
      &.customer-service-callout__text {
        font-family: $font_1;
      }
    }
  }
  .cs-quick-info {
    .cs-quick-info__title {
      font-family: $font_1;
    }
  }
  .careers-page {
    .careers-page__landing-link {
      font-family: $font_1;
    }
    .careers-landing {
      .career-landing__profile-link {
        font-family: $font_1;
      }
    }
  }
  .need-help-panel {
    .phone,
    .chat,
    .email {
      h4 {
        font-family: $font_1;
      }
    }
    .need-help-panel__title {
      font-family: $font_1;
    }
  }
  /* Beauty Profile */
  .beauty-feed-tout--products .sku-brief-editorial__shadename {
    font-family: $font_1;
  }
  .beauty-profile__tools-header,
  .beauty-profile__tools-link {
    font-family: $font_1;
  }
  .beauty-questions__label {
    font-family: $font_1;
  }
  .beauty-profile__questions-submit {
    font-family: $font_1;
  }
  .beauty-chat-tray {
    .beauty-chat-tray .beauty-chat-tray__learn-more {
      font-family: $font_1;
    }
    .beauty-chat-tray__title {
      font-family: $font_1;
    }
    .beauty-chat-tray__hours-of-availability,
    .beauty-chat-tray__hours-of-availability p {
      font-family: $font_1;
    }
  }
  .beauty-feed-tout {
    .social-user {
      font-family: $font_1;
    }
    .social-action {
      font-family: $font_1;
    }
  }
  .beauty-consultations {
    font-family: $font_1;
  }
  .formatter-beauty-feed__header > .headline,
  .formatter-beauty-feed__footer > .headline {
    font-family: $font_1;
  }
  .beauty-feed-tout--tool__headline {
    font-family: $font_1;
  }
  .beauty-feed-tout--twitter .social-content {
    font-family: $font_1;
  }
  .beauty-feed-tout--products .sku-brief-editorial__size {
    font-family: $font_1;
  }
  /* My feed */
  .my-feed-tray {
    .my-feed-tray__anon {
      .my-feed-summary__header,
      .my-feed-summary__copy {
        font-family: $font_1;
      }
    }
    .my-feed-tray__video-header,
    .my-feed-tray__video-title {
      font-family: $font_1;
    }
  }
  .my-feed-summary__welcome-back,
  .my-feed-summary__signout {
    font-family: $font_1;
  }
  /* Special offers */
  .special-offers-tray {
    .offers-email-signup-form .headline--offers-tray,
    .offers-register-form .headline--offers-tray,
    .offers-sign-in-form .headline--offers-tray {
      font-family: $font_1;
    }
    .offers-email-signup-form .offers-email-signup-form__sign-in-wrapper {
      font-family: $font_1;
      a {
        font-family: $font_1;
      }
    }
    .offers-email-signup-form .offers-email-signup-form__message {
      font-family: $font_1;
    }
    .gift-wrap .gift-wrap__content,
    .gift-wrap .special-offer-benefits__content,
    .special-offer-benefits .gift-wrap__content,
    .special-offer-benefits .special-offer-benefits__content {
      font-family: $font_1;
    }
    .gift-wrap .gift-wrap__link-wrapper a,
    .gift-wrap .special-offer-benefits__link-wrapper a,
    .special-offer-benefits .gift-wrap__link-wrapper a,
    .special-offer-benefits .special-offer-benefits__link-wrapper a {
      font-family: $font_1;
    }
  }
  /* Botique */
  .boutique-tout {
    .boutique-tout-eyebrow-wrapper .boutique-tout-eyebrow {
      font-family: $font_1;
    }
    .boutique-tout-main-headline-wrapper .boutique-tout-main-headline {
      font-family: $font_1;
    }
    .boutique-tout-copy-block-wrapper .boutique-tout-copy-block > span {
      font-family: $font_1;
    }
    &.boutique-tout-size-small {
      .boutique-tout-eyebrow-wrapper .boutique-tout-eyebrow {
        font-family: $font_1;
      }
      .boutique-tout-main-headline-wrapper .boutique-tout-main-headline {
        font-family: $font_1;
      }
      .boutique-tout-copy-block-wrapper .boutique-tout-copy-block > span {
        font-family: $font_1;
      }
    }
  }
  .boutique-tout-button {
    font-family: $font_1;
  }
  .boutique-custom__link {
    a.button-explore.button-explore-label {
      font-family: $font_1;
    }
  }
  .ff-quiz__inner {
    .ff-quiz__headline,
    .ff-quiz__edit-headline,
    .ff-quiz__headline--secondary {
      font-family: $font_1;
    }
    .ff-quiz__headline--tertiary,
    .ff-quiz__nav-menu {
      font-family: $font_1;
    }
    .ff-quiz__button--next {
      font-family: $font_1;
    }
    .ff-quiz__coverage-image,
    .ff-quiz__intensity-image {
      font-family: $font_1;
    }
  }
  .ff-quiz--edit {
    .ff-quiz__inner {
      .ff-quiz__headline--secondary {
        font-family: $font_1;
      }
    }
  }
  .ff-results__regimen-step-product .product_brief__header,
  .ff-results__regimen-step-product .product_brief__sub-header,
  .ff-results__product .sku-brief-editorial__shadename {
    font-family: $font_1;
  }
  .ff-results {
    .ff-results__matches-sub-headline {
      font-family: $font_1;
    }
    .ff-results__regimen-step-headline {
      font-family: $font_1;
    }
    .ff-results__count {
      font-family: $font_1;
    }
    .ff-results__matches-copy,
    .ff-results__matches-copy a,
    .ff-results__matches-copy p {
      font-family: $font_1;
    }
    .ff-results__regimen-step-instructions,
    .ff-results__regimen-step-instructions a {
      font-family: $font_1;
    }
    .ff-results__regimen-step-gutter {
      font-family: $font_1;
    }
  }
  .ff-results__product .sku-brief-editorial .sku-brief-editorial__size {
    font-family: $font_1;
  }
  .ff-results__regimen-step-product .product_brief__price {
    font-family: $font_1;
  }
  /* Responsive carousel */
  .responsive-carousel {
    .slide__caption {
      font-family: $font_1;
    }
    .slide__link {
      font-family: $font_1;
    }
  }
  .responsive-carousel__title {
    font-family: $font_1;
  }
  .r3__container {
    .r3__states .r3__sub-header {
      font-family: $font_1;
    }
    .r3__overlay .r3__overlay-header {
      font-family: $font_1;
    }
  }
  .homepage-subscribe .homepage-subscribe__header {
    font-family: $font_1;
  }
  .sms-signup__terms label {
    font-family: $font_1;
  }
  .country-chooser__selector {
    font-family: $font_1;
  }
  .wishlist-confirm__message {
    font-family: $font_1;
  }
  .estee-edit__header-tags-label {
    font-family: $font_1;
  }
  .formatter-estee-edit__content {
    .basic-textarea-v1 {
      p {
        font: 18px/26px $font_1;
      }
    }
  }
  .ee-first-letter {
    font: 26px/60px $font_1;
  }
  .up-next__label {
    font-family: $font_1;
  }
  div.livechat-transcripts-page dl.transcripts {
    font-family: $font_1;
    dd.transcript div {
      &.transcript-lines h3.transcript-section-header {
        font-family: $font_1;
      }
      &.recommended-skus h3.transcript-section-header {
        font-family: $font_1;
      }
    }
  }
  /* Footer */
  .page-footer {
    font-family: $font_1;
    .footer-header,
    .country_chooser__header,
    .sms-signup__header,
    .email_signup__header {
      font-family: $font_1;
    }
    .language-select {
      .language-select__header {
        font-family: $font_1;
      }
      .language-select__language01,
      .language-select__language02 {
        font-family: $font_1;
      }
    }
  }
  .page-sticky-footer {
    .drawer-formatter__content .special-offer {
      .special-offer__text {
        font-family: $font_1;
      }
      .special-offer__header {
        font-family: $font_1;
      }
    }
    .page-sticky-footer__left .node-elc-nodeblock .sticky-footer__my-feed-link .item-count {
      font-family: $font_1;
    }
    .page-sticky-footer__right .menu > li .sticky-footer__my-feed-link .item-count {
      font-family: $font_1;
    }
  }
  .footer-phone .footer-phone__link {
    font-family: $font_1;
  }
  /* Store locator */
  &.section-store-locator.device-pc {
    .store-locator {
      .store-locator__title {
        font-family: $font_1;
      }
      .location-info {
        font-family: $font_1;
      }
      .intl-search {
        .intl-search__label {
          font-family: $font_1;
        }
        .intl-search__copy {
          font-family: $font_1;
        }
      }
      .store-results-header {
        font-family: $font_1;
      }
      .door-row {
        .door-row__name {
          font-family: $font_1;
        }
        .door-row__closest {
          font-family: $font_1;
        }
      }
      .local-search__option {
        font-family: $font_1;
        &.active {
          font-family: $font_1;
        }
      }
      .directions-form__results {
        font-family: $font_1;
      }
      .store-locator__nav .store-locator__nav-item {
        font-family: $font_1;
        &.active {
          font-family: $font_1;
        }
      }
      .doors-results__pager .doors-results__pager-view-all {
        font-family: $font_1;
      }
    }
    .store-locator-location-info .location-info__door-name {
      font-family: $font_1;
    }
    .find-a-store-page .promo-unit--threecol .promo-unit__headline {
      font-family: $font_1;
    }
  }
  .store-locator-block {
    .store-locator-block__results {
      .store-locator-block__result-doorname {
        font-family: $font_1;
      }
      .store-locator-block__result-address {
        font-family: $font_1;
      }
      .store-locator-block__results-header {
        font-family: $font_1;
      }
      .store-locator-block__link a {
        font-family: $font_1;
      }
    }
    .store-locator-block__errors {
      font-family: $font_1;
    }
    .store-locator-block__header {
      font-family: $font_1;
    }
    .store-locator-block__loading-message {
      font-family: $font_1;
    }
  }
  &.brand-aerin {
    select,
    a.selectBox,
    a.selectbox {
      font-family: $font_1;
    }
    blockquote,
    .pull-quote {
      font-family: $font_1;
    }
    .page-main {
      font-family: $font_1;
      .product_brief__price {
        font-family: $font_1;
      }
      .product_brief__misc-flag {
        font-family: $font_1;
      }
      .text-form-text,
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      input[type='password'],
      textarea,
      .form-text,
      select,
      a.selectBox,
      a.selectbox {
        font-family: $font_1;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $font_1;
      }
      p {
        font-family: $font_1;
      }
      .headline--primary,
      .headline--large,
      .headline--secondary,
      .headline--medium,
      .headline--tertiary,
      .headline--quinary,
      .headline--page,
      .headline--section,
      .headline--senary,
      .headline--quaternary,
      .headline--products,
      .profile-info__header,
      blockquote,
      .pull-quote {
        font-family: $font_1;
      }
      .text--tiny,
      .text--tiny-copy,
      .text--long-copy,
      .text-long-body-copy,
      .text-primary-headlines,
      .text-medium-headlines,
      .text-batch-2-primary-headline,
      .text-batch-2-medium-headline,
      .text-product-names-main,
      .text-product-names-sub,
      .text-product-listing-names-main,
      .text-product-listing-names-sub,
      .text-benefits-line-sub,
      .text-size,
      .text-promo,
      .text--short {
        font-family: $font_1;
      }
      label {
        font-family: $font_1;
      }
      .selectBox-options li a {
        font-family: $font_1;
      }
      .button {
        font-family: $font_1;
      }
      .spp-product__anchor,
      .text-product-anchor {
        font-family: $font_1;
      }
      .discover-more__header,
      .discover_more__product-header,
      .discover_more__product-sub-header {
        font-family: $font_1;
      }
      .cart-item__qty {
        font-family: $font_1;
      }
      .pc-place-order {
        font-family: $font_1;
      }
      .spp-product__details .page-main {
        .spp-product__details-description {
          font-family: $font_1;
        }
      }
      .product-brief__view-details {
        font-family: $font_1;
      }
      .mpp__header,
      .spp-product,
      .spp-product__review,
      .product-full__review,
      .product-full__attribute p,
      .spp-product__attribute p {
        font-family: $font_1;
      }
      .product-full__misc-flag .product-full,
      .product-full__detail-link .product-full__share,
      .product-full__wishlist,
      .product-brief__view-details {
        font-family: $font_1;
      }
      .spp-product__details {
        .spp-product__details-header,
        .spp-product__details-description {
          font-family: $font_1;
        }
      }
      .product_brief__header,
      .review-submit__header,
      .checkout-page-title,
      .checkout__sidebar .links-panel__title,
      .add-samples-message,
      .samples-page .available {
        font-family: $font_1;
      }
      .typeahead-wrapper {
        .product-result__name {
          font-family: $font_1;
        }
      }
      .typeahead-summary p {
        font-family: $font_1;
      }
      #colorbox.colorbox__quickshop {
        .quickshop,
        .quickshop__attribute p,
        .quickshop__review {
          font-family: $font_1;
        }
      }
      .ff-results__product {
        .sku-brief-editorial__shadename {
          font-family: $font_1;
        }
      }
      .beauty-feed-tout--products {
        .sku-brief-editorial__shadename {
          font-family: $font_1;
        }
      }
      .chat-parallax__headline {
        font-family: $font_1;
      }
      .hero-block--aerin {
        .hero-block__promo .text-promo__content {
          font-family: $font_1;
        }
        .cta__button {
          font-family: $font_1;
        }
        &.button-dark {
          font-family: $font_1;
        }
        .hero-block__headline {
          .header__headline {
            font-family: $font_1;
          }
        }
      }
      .homepage-subscribe {
        .homepage-subscribe__header {
          font-family: $font_1;
        }
      }
      .special-offers-tray {
        .offers-email-signup-form {
          .headline--offers-tray {
            font-family: $font_1;
          }
          .offers-email-signup-form__sign-in-wrapper {
            font-family: $font_1;
          }
          .offers-email-signup-form__message {
            font-family: $font_1;
          }
        }
        .offers-register-form .headline--offers-tray {
          font-family: $font_1;
        }
        .offers-sign-in-form .headline--offers-tray {
          font-family: $font_1;
        }
        .gift-wrap {
          .special-offer-benefits__content,
          .gift-wrap__content {
            font-family: $font_1;
          }
        }
        .special-offer-benefits {
          .gift-wrap__content,
          .special-offer-benefits__content {
            font-family: $font_1;
          }
        }
      }
      .ee--subhead,
      .sms-signup__terms label {
        font-family: $font_1;
      }
      .page-footer {
        font-family: $font_1;
      }
      .page-sticky-footer {
        .page-sticky-footer__left {
          .node-elc-nodeblock .sticky-footer__my-feed-link .item-count {
            font-family: $font_1;
          }
        }
        .page-sticky-footer__right .menu > li .sticky-footer__my-feed-link .item-count {
          font-family: $font_1;
        }
      }
    }
  }
  .woa-blog__content {
    .ee-disco-more__label {
      font-family: $font_1;
    }
  }
  .typeahead-wrapper {
    .brand-aerin .page-main {
      .product-result__name,
      .product-result__sub-name {
        font-family: $font_1;
      }
    }
  }
  .spp-product__details {
    .brand-aerin .page-main {
      .spp-product__details-header {
        font-family: $font_1;
      }
    }
  }
  .beauty-feed-tout--products,
  .ff-results__product {
    .brand-aerin .page-main {
      .sku-brief-editorial__shadename {
        font-family: $font_1;
      }
    }
  }
  .product-full__attribute,
  .spp-product__attribute {
    .brand-aerin .page-main {
      p {
        font-family: $font_1;
      }
    }
  }
  .aerin-footer-tout__headline {
    font-family: $font_1;
  }
  .hero-block--holiday {
    .hero-block__headline {
      .header__headline {
        font-family: $font_1;
      }
    }
  }
  /* brand-renutriv page style */
  &.brand-renutriv {
    .page-main {
      font-family: $font_1;
      p {
        font-family: $font_1;
        &.copy {
          font-family: $font_1;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $font_1;
      }
    }
    .headline--primary,
    .headline--large,
    .headline--secondary,
    .headline--medium,
    .headline--page,
    .headline--products,
    blockquote,
    .pull-quote {
      font-family: $font_1;
    }
    .text-primary-headlines,
    .text-medium-headlines {
      font-family: $font_1;
    }
    h4.discover_more__product-header {
      font-family: $font_3;
    }
    .product-full {
      .product-full__description-section {
        h3.product-full__title {
          font-family: $font_3;
        }
      }
    }
    .discover_more__product-sub-header,
    .discover_more__product-shopnow {
      font-family: $font_1;
    }
    select,
    a.selectBox,
    a.selectbox,
    .selectBox-options li a {
      font-family: $font_1;
    }
    .spp-product__details {
      .spp-product__details-header {
        font-family: $font_1;
      }
      .spp-product__details-description {
        font-family: $font_1;
        p {
          font-family: $font_1;
        }
      }
      .spp-product__details-attribute__label {
        font-family: $font_1;
      }
      .spp-product__details-attribute {
        font-family: $font_1;
        p {
          font-family: $font_1;
        }
      }
    }
    .product-full {
      font-family: $font_1;
      .cta {
        font-family: $font_1;
      }
      .product-full__description-section {
        .h3.product-full__title {
          font-family: $font_3;
        }
      }
    }
    .product-full__misc-flag,
    .product-full__subtitle,
    .product-full__detail-link,
    .product-full__share,
    .product-full__wishlist {
      font-family: $font_1;
    }
    .product-full__attribute {
      h5 {
        font-family: $font_1;
      }
      p {
        font-family: $font_1;
      }
    }
    .mpp__product {
      .product_brief__headers {
        .product_brief__header,
        .product-full h3 {
          font-family: $font_3;
          &.product_brief__sub-line {
            font-family: $font_1 !important;
          }
        }
      }
    }
    .mpp__header {
      font-family: $font_1 !important;
    }
    .product_brief__misc-flag,
    .product_brief__sub-header,
    .product_brief__price {
      font-family: $font_1;
    }
    .product_brief {
      .button--secondary {
        font-family: $font_1;
      }
    }
    .product_brief__buttons--non-shaded,
    .product_brief__buttons--shaded {
      .product_brief__button--shop-now {
        font-family: $font_1;
      }
    }
    .spp-product__attribute {
      p {
        font-family: $font_1;
      }
    }
    #colorbox.colorbox__quickshop {
      .quickshop {
        font-family: $font_1;
        h3 {
          font-family: $font_1;
        }
        .quickshop__view-full,
        .quickshop__sub-header {
          font-family: $font_1;
        }
      }
      .quickshop__attribute {
        h5 {
          font-family: $font_1;
        }
        p {
          font-family: $font_1;
        }
      }
      .quickshop__add-button,
      .quickshop__detail-link,
      .quickshop__share,
      .quickshop__wishlist {
        font-family: $font_1;
      }
      .quickshop__tabs-control {
        .quickshop__tab-control {
          font-family: $font_1;
        }
      }
    }
    .hero-tout-fullwidth {
      .text-promo {
        font-family: $font_1;
      }
      .cta-button-wrapper .cta {
        font-family: $font_1;
      }
    }
    .chat-parallax__headline {
      font-family: $font_1;
    }
    .sms-signup__terms {
      label {
        font-family: $font_1;
      }
    }
    .hero-block--renutriv {
      .hero-block__headline {
        .header__headline {
          font-family: $font_1;
        }
      }
    }
  }
  /* end - brand-renutriv page style */
  .hero-block--renutriv {
    .hero-block__promo .text-promo__content {
      font-family: $font_1;
    }
  }
  /* BV Implementation */
  .BV,
  .BVSubmissionTimeout {
    font-family: $font_1;
  }
  .BVRRTitle,
  .BVMEPageHeader,
  .BVMESectionHeader {
    font-family: $font_1;
  }
  button.BVButton,
  .BVFieldPreview .BVButton,
  .BVFieldSubmit .BVButton {
    font-family: $font_1;
  }
  .BVSelectField div.selector select {
    font-family: $font_1;
  }
  .uploader {
    font-family: $font_1;
    input[type='file'] {
      font-family: $font_1;
    }
  }
  .BVRRRootElement {
    font-family: $font_1;
    input,
    select,
    textarea {
      font-family: $font_1;
    }
  }
  .BVRRContainer {
    div.selector {
      select {
        font-family: $font_1;
      }
    }
  }
  .BVDI_FV {
    .BVDI_FVVote {
      a {
        font-family: $font_1;
        &:hover,
        &.focus,
        &:active {
          font-family: $font_1;
        }
      }
    }
  }
  .BVRRPhotoPopup,
  .BVRRVideoPopup {
    font-family: $font_1;
  }
  &.device-mobile {
    .sign-in-component {
      .sign-in-component__confirm--registration .sign-in-component__header {
        font-family: $font_1;
      }
    }
    .page-sticky-footer .page-sticky-footer__right .menu a {
      font-family: $font_1;
    }
    .footer-forms-location-sms-email-signup-elc-nodeblock {
      .footer-forms__header {
        font-family: $font_1;
      }
    }
    .ee-landing__filters {
      font-family: $font_1;
    }
    .account-mobile-landing-page {
      .account-landing-menu {
        .account-landing-menu__link {
          font-family: $font_1;
        }
        .brand-aerin .page-main .account-landing-menu__link {
          font-family: $font_1;
        }
      }
      .account-landing-profile {
        .account-landing-profile__header {
          font-family: $font_1;
        }
        .account-landing-profile__content .account-landing-profile__details {
          .account-landing-profile__details-header {
            font-family: $font_1;
          }
        }
      }
    }
    .product-brief__button-quickshop--close {
      font-family: $font_1;
    }
    .quickshop-inline {
      .quickshop-inline__attribute-header,
      .quickshop-inline__wishlist,
      .quickshop-inline__details {
        font-family: $font_1;
      }
      .quickshop-inline__attribute-text {
        font-family: $font_1;
      }
    }
    .spp-product__details {
      font-family: $font_1;
      h5 {
        font-family: $font_1;
      }
      li {
        font-family: $font_1;
      }
    }
    .text-product-names-main,
    .text-product-names-sub,
    .spp-product__header,
    .spp-product__sub-header,
    .spp-howtouse__header {
      font-family: $font_1;
    }
    .product-brief__sub-header {
      font-family: $font_1;
    }
    &.brand-aerin {
      .page-main {
        .sign-in-component .sign-in-component__confirm--registration .sign-in-component__header {
          font-family: $font_1;
        }
        .ee-landing__filters {
          font-family: $font_1;
        }
        .headline--section,
        .profile-info__header {
          font-family: $font_1;
        }
      }
    }
  }
}

.product-full {
  .product-full__description-section {
    h3.product-full__title {
      font-family: $font_2;
      &.product_brief__sub-line {
        font-family: $font_1;
        font-size: 30px;
      }
    }
  }
  .product-full__subtitle {
    font-family: $font_2;
    font-size: 30px;
  }
  .product_brief__sub-header {
    font-family: $font_2;
    font-size: 30px;
  }
}

.mpp__product {
  .product_brief__headers {
    h3.product_brief__header {
      font-family: $font_5;
      font-size: 22px;
      line-height: 25px;
      &.product_brief__sub-line {
        font-family: $font_1;
      }
    }
    .product_brief__sub-header {
      font-family: $font_5;
      font-size: 16px;
    }
  }
}

.page-navigation {
  .estée-edit.level-1 {
    font-family: 'AkzidenzGrotesk W1G', 'Microsoft YaHei', 'Microsoft JhengHei', 'Hiragino Sans GB', 'STHeiti', 'sans-serif',
      'Tahoma', 'arial';
  }
}

.cleansers-toners-elc-mpp,
.new-dimension-page,
.foundation-custom-mpp-page,
.lip-gloss-elc-mpp,
.contouring-page,
.landing-best-sellers-page {
  .hero-block__headline {
    .header__headline {
      span {
        font-size: 55px;
        font-family: $font_2;
      }
    }
  }
}

.nail-lacquer-page {
  .hero-block__headline {
    .header__headline {
      span {
        font-family: $font_2;
      }
    }
  }
}

.repair-page {
  .hero-tout__header {
    .header__headline {
      span {
        font-family: $font_2;
      }
    }
  }
}

.eyeshadow-elc-mpp {
  .tout-headline {
    .header__headline {
      span {
        font-size: 55px;
        font-family: $font_2;
      }
    }
  }
}

body {
  .page-navigation {
    .level-1 {
      font-family: 'AkzidenzGrotesk W1G';
    }
  }
}

.header__headline {
  .three_Min_Beauty,
  .new_envy_gloss span.eng_text {
    font-family: $font_2;
  }
}

.device-pc {
  .header__headline {
    font-size: 55px !important;
  }
  .text-promo__subcopy {
    font-size: 24px !important;
  }
}

.elc-product-full .elc-product-display-name-link,
.product-full__title {
  font-family: $font_1;
}

.spp-product__details-container {
  .spp-product__details {
    .spp-product__details-column {
      .spp-product__details-attribute {
        .spp-product__details-attribute__label {
          font-family: $font_4;
        }
        p {
          font-family: $font_1;
        }
      }
    }
    .spp-product__details-column02 {
      .spp-product__details-attribute {
        p {
          font-family: $font_1;
        }
      }
    }
  }
}

.spp-howtouse {
  .spp_howtouse__product {
    .spp_howtouse__product-details {
      .text-product-listing-names-main {
        font-family: $font_5;
      }
    }
  }
}
/* Home Page Font Style Change */

.hero-block__promo.hero-block__promo--left {
  .subcopy.text-promo__subcopy {
    span {
      font-family: $font_6;
    }
  }
}

.nutritious p {
  font-family: $font_6;
}
/* End Home Page Font Style */
.optanon-show-settings-popup-wrapper {
  .optanon-show-settings-button {
    &.optanon-toggle-display {
      .optanon-show-settings-middle {
        .optanon-show-settings {
          font-family: $font_1;
        }
      }
    }
  }
}
