.device-pc {
  #appointment-book-container {
    .appt_mobile_phone {
      .appt_kr_phone_code {
        float: left;
        margin-left: 3.7%;
        height: 40px;
        margin-right: 0.5%;
        width: 15%;
      }
      .appt_mobile_phone_seperator {
        float: left;
        width: 1%;
        margin-top: 1%;
      }
      .appt_mobile_part1 {
        float: left;
        height: 40px;
        width: 15%;
        margin-right: 0.6%;
      }
      .appt_mobile_part2 {
        width: 15%;
        float: left;
        height: 40px;
      }
    }
    .registration__email-list {
      .registration__email-list-text {
        cursor: text;
      }
    }
    .registration__privacy-policy-list {
      .registration__email-list-text {
        margin-left: 24px;
        cursor: text;
      }
    }
  }
}

.section-store-locator {
  &.device-pc {
    .store-locator-location-info {
      .store-locator_book-appointment {
        p {
          margin-top: 7px;
        }
      }
    }
  }
}

.book-appt-container {
  .appt-book-first-name,
  .appt-book-email {
    color: $color-black;
  }
  .reservation_instructions {
    color: $color-red;
  }
}

.appt-book {
  .location-select-section-content__title {
    padding-bottom: 39px;
  }
  .service-select {
    .service {
      .service-details {
        h3.service-title {
          font-size: 20px;
          min-height: 69px;
          position: relative;
          top: 0.3em;
        }
      }
    }
  }
}

#appointments {
  .legal_wrapper {
    .legal_statement {
      border: 1px solid $color-black;
      margin: 16px 0px;
      overflow-y: scroll;
      height: 150px;
      width: 99.9%;
      font-size: 14px;
    }
    h4 {
      margin-top: 25px;
      font-weight: 700;
      font-size: 18px;
      &.accept-oab-sms-head {
        margin-left: 30px;
      }
    }
    .oab_sms_wrapper {
      width: 49.5%;
      display: inline-block;
      .legal_statement {
        width: 95%;
        margin-right: 0;
        margin-left: 31px;
      }
      .radio_group {
        &.email_block {
          margin-left: 33px;
        }
      }
    }
    .terms_wrapper {
      width: 50%;
      display: inline-block;
      .legal_statement {
        width: 95%;
        height: 150px;
        overflow-y: scroll;
      }
    }
    .radio_group {
      h3 {
        font-size: 18px;
        font-weight: 700;
      }
      label {
        font-size: 14px;
      }
      &.marketing_three_channels {
        height: 54px;
        display: inline-block;
        float: left;
        margin-right: 5%;
        line-height: 26px;
      }
      &.postal_block {
        float: none;
      }
    }
  }
  .appt-book-content-header {
    .step3-text {
      margin-top: 8px;
      margin-left: 70px;
      font-weight: 700;
      font-size: 1.125rem;
      letter-spacing: 1px;
      text-align: left;
      list-style-position: inside;
      span {
        font-size: 20px;
        .max_reservation {
          color: $color-red;
        }
      }
      ul {
        display: block;
        padding-inline-start: 2.5rem;
      }
      li {
        list-style: disc;
      }
    }
    .reservation_header {
      text-align: left;
      margin-left: 51px;
    }
  }
  .reservation_instructions {
    .reserv_instructions {
      color: $color-red;
      font-weight: 700;
      a {
        color: $color-red;
        text-decoration: underline;
      }
    }
  }
  .online_reserve_guide {
    display: block;
    margin-right: 50em;
    font-size: 19px;
  }
}
