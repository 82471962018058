/* Power reviews */

.spp {
  .review-snippet {
    .p-w-r {
      .pr-snippet-stars-reco-inline {
        &.pr-snippet-minimal,
        &.pr-snippet-compact,
        &.pr-snippet-standard {
          .pr-snippet-read-and-write {
            font-family: $optimalight;
            a,
            span {
              font-family: $optimalight;
            }
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-read-and-write {
            .pr-snippet-write-review-link {
              font-family: $optimalight;
            }
          }
        }
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-sort {
          font-family: $optimalight;
        }
        .pr-rd-review-total {
          clear: both;
        }
      }
      .pr-review {
        .pr-rd-header {
          .pr-rd-review-headline {
            font-family: $optimalight;
          }
        }
        .pr-rd-description {
          .pr-rd-description-text {
            font-family: $optimalight;
          }
          .pr-rd-right {
            .pr-rd-reviewer-details {
              p,
              time {
                font-family: $optimalight;
              }
              p {
                .pr-rd-bold {
                  font-family: $optimalight;
                }
              }
            }
          }
        }
        .pr-rd-merchant-response {
          .pr-rd-description-text {
            font-family: $optimalight;
          }
          .pr-rd-merchant-response-headline {
            strong {
              font-family: $optimalight;
            }
          }
        }
        .pr-accordion {
          .pr-accordion-btn {
            span {
              font-family: $optimalight;
            }
          }
          .pr-accordion-content {
            dl {
              dt,
              dd {
                font-family: $optimalight;
              }
            }
          }
        }
        .pr-rd-footer {
          .pr-helpful {
            &-yes {
              .pr-helpful-count {
                font-family: $optimalight;
                &:before {
                  content: '도움이 되지 않았습니다 (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                font-family: $optimalight;
                &:before {
                  content: '도움이 되었습니다 (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
          }
          .pr-rd-bottomline {
            span {
              font-family: $optimalight;
            }
          }
          .pr-rd-flag-review-container {
            a {
              font-family: $optimalight;
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              font-family: $optimalight;
            }
            .pr-control-label,
            .pr-flag-review-label {
              font-family: $optimalight;
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            font-family: $optimalight;
          }
          .pr-rd-pagination-btn,
          .pr-rd-review-position span {
            font-family: $optimalight;
          }
        }
      }
      .pr-rd-no-reviews {
        font-family: $optimalight;
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='되십니까'] {
          &::before {
            content: '필터:';
            font-size: 12px;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-multiselect[data-reactid-powerreviews$='되었습니까'] {
          display: none;
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              content: '필터: ';
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            .pr-search-icon {
              &::after {
                content: '가기';
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 69.7%;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' / 5';
            }
          }
        }
      }
    }
  }
}

.write-a-review {
  &__container {
    #pr-write {
      .pr-subscript {
        display: none;
      }
    }
  }
}
