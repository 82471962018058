.ie-8 {
  .cboxIE {
    &.colorbox {
      &__us-ie-upgrade {
        #cboxContent {
          #cboxClose {
            right: 43px;
          }
        }
      }
    }
  }
}
