.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.error_messages {
  color: $color-red;
}

select::-ms-expand {
  display: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

input[type='tel'] {
  color: $color-black;
}

input[type='radio'] ~ label,
input[type='radio'] ~ .label {
  margin-left: 1px;
}

#cboxLoadedContent {
  .sign-in-component {
    overflow-y: auto;
    overflow-x: hidden;
    height: 475px;
  }
}

#address_form_container {
  .address-form {
    &__phone-1-container,
    &__phone-2-container {
      .selectBox,
      select {
        width: 36%;
      }
      input {
        &.field {
          width: 30%;
        }
      }
    }
  }
  #address-form-popover {
    .input_town {
      width: auto;
    }
  }
}

.colorbox {
  &__wishlist-confirm {
    #cboxLoadedContent {
      margin: 0;
      height: auto !important;
    }
  }
}

.ui-widget-content {
  height: 12em;
  overflow-y: auto;
  width: 38em;
}

.form_element {
  margin-top: 0.4em;
  margin-bottom: 0.8em;
}

.margin_top_bottom {
  margin: 0.8em 0;
}

.customer-service .sidebar-page {
  &__content {
    h2.additional_info_popup_header {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      border: none;
      margin: 0 0 20px 0;
      padding: 0;
    }
    h3.titleNew {
      font-size: 18px;
      font-weight: 800;
      margin: 1em 0 0.5em;
    }
  }
}

.shipping-detail-popup,
.return-exchanges-popup,
.max-purchase-policy-popup {
  .sidebar-page {
    &__content li {
      list-style-type: none;
    }
    &__content ol {
      padding-left: 30px;
    }
  }
}

body {
  .product_brief__header.product_brief__sub-line,
  .cart-item .product_subline,
  .text-product-listing-names-sub {
    font-size: 14px;
  }
  .cart-items products .product_subline,
  #colorbox.colorbox__quickshop .quickshop .quickshop__sub-line {
    line-height: 50px;
  }
  .product-full {
    .product-full__subline {
      font-size: 23px;
      line-height: 1.45;
    }
  }
}

.country-chooser {
  margin: 0.8em 0;
}

.page-footer {
  .page-sticky-footer {
    &__left .my-feed-elc-nodeblock {
      border-left: none;
    }
  }
}

.email_signup_sucess_popup {
  #cboxContent {
    background-color: transparent;
    height: 100% !important;
    overflow: visible;
  }
  #cboxLoadedContent {
    color: white;
    overflow: visible !important;
  }
}

.menu-item-container--childcount-3 {
  width: 16.6%;
}

.ee-social-share {
  padding: 5px 10px 0 10px;
  .twitter,
  .pinterest {
    display: none;
  }
}

.product-full {
  .product-full__personal {
    .social-share-icons {
      .social-share__link {
        &.twitter,
        &.pinterest {
          display: none;
        }
      }
    }
  }
  h3.product_brief__sub-line {
    line-height: 35px;
    margin: 35px 0 20px 0;
  }
}

#colorbox.colorbox__quickshop {
  .quickshop__description {
    height: 620px;
    overflow-y: scroll;
  }
  .quickshop__tab-container {
    height: auto;
  }
  .quickshop__personal {
    display: inline-block;
  }
  .quickshop__view-full {
    position: inherit;
  }
}

.mpp-compare-vba {
  display: none;
}

.special-offers-page {
  .formatter-123__title-2 {
    font-size: 33px;
  }
}

.repair-page {
  .hero-tout__header {
    .header__headline {
      span {
        font-size: 115px;
        line-height: 110px;
      }
    }
  }
}

.contouring-page {
  .hero-block__headline {
    h1.header__headline {
      span {
        font-size: 95px;
      }
    }
  }
}

.mascara-elc-mpp {
  .hero-block__headline {
    right: 0;
    .header__headline {
      span {
        font-size: 60px;
        line-height: 65px;
      }
    }
  }
}

.foundation-custom-mpp-page {
  .hero-block__headline {
    h1.header__headline {
      span {
        font-size: 101px;
      }
    }
  }
}

.ban_head {
  font-size: 55px;
  line-height: 55px;
}

.ban_subhead {
  font-size: 24px;
  line-height: 24px;
}

.device-pc {
  .discover_more__product-quickshop {
    position: absolute;
    bottom: 0;
    left: 20%;
    right: 20%;
  }
  .discover_more__product-price {
    margin: 20px 0 10px 0;
  }
  .discover-more__header {
    padding: 0 0 70px;
  }
}

.spp-howtouse {
  .spp_howtouse__header {
    div.headline--secondary {
      font-size: 35px;
    }
  }
}
/* Home Page Style Change */

.hero-block__promo.hero-block__promo--left {
  left: 45%;
  top: 22%;
  .subcopy.text-promo__subcopy {
    span {
      font-size: 24px;
    }
  }
}

.nutritious p {
  font-size: 24px;
}

.envy_lustre {
  .hero-block__headline {
    top: 12%;
  }
  .hero-block__promo {
    top: 24%;
  }
}

.hero-block__headline.hero-block__headline--left {
  left: 45%;
  right: auto;
  text-align: left;
  width: auto;
  .header__headline.headline--medium {
    span {
      font-size: 55px;
    }
  }
}

.hero-block__headline.hero-block__headline--center {
  bottom: 28%;
}

.hero-block__promo.hero-block__promo--center {
  top: 72%;
  .life_style {
    span {
      line-height: 24px;
      font-size: 16px;
    }
  }
}

.double_wear_sub {
  margin-top: 15px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .hero-block__promo.hero-block__promo--left {
    right: auto;
    bottom: auto;
  }
  .double_wear {
    .hero-block__promo {
      top: 26%;
    }
  }
  .home-block {
    .micro_clean {
      .hero-block {
        &__promo--left {
          top: 32%;
        }
      }
    }
  }
  .hero-block__promo.hero-block__promo--center {
    top: 70%;
  }
  .micro_clean_head {
    margin-top: 15px;
    display: block;
    line-height: 60px;
  }
  .envy_lustre {
    .hero-block__headline {
      top: 56%;
    }
    .hero-block__promo {
      top: 70%;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .hero-block__promo.hero-block__promo--left {
    top: 36%;
    right: auto;
    bottom: auto;
  }
  .hero-block__headline.hero-block__headline--left {
    right: auto;
    h1.header__headline {
      line-height: 60px;
    }
  }
  .hero-block__promo.hero-block__promo--center {
    top: 67%;
  }
  #content {
    .home-formatter {
      .home-block .crescent_white {
        .hero-block__promo.hero-block__promo--left {
          top: 38%;
        }
      }
    }
  }
  .page-sticky-footer {
    .page-sticky-footer__right {
      position: absolute;
      right: 0;
    }
  }
  .envy_lustre {
    .hero-block__headline {
      top: 54%;
    }
    .hero-block__promo {
      top: 70%;
    }
  }
}

#content {
  .home-block {
    .nutritious {
      .hero-block {
        &__promo {
          top: 18%;
          right: 10%;
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            top: 42%;
          }
        }
      }
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper,
    .lp_checkbox_wrapper {
      label {
        &::after,
        &::before {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}
/* End Home Page Style */

a.swatch {
  &.out-of-stock {
    &:after {
      color: $color-white;
      content: '\2044';
      font-size: 27px;
      line-height: 0.9em;
      transform: rotate(90deg);
      z-index: 1000;
      cursor: pointer;
      position: absolute;
      top: 2px;
      left: 13px;
    }
  }
}
